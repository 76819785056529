// Buttons
.btn {
  border-radius: 20px;
  @apply flex items-center justify-center;
  @apply p-2;
  @apply text-blue-80;
  @apply border border-solid border-blue-80;
  @apply rounded-3xl;

  &-bl {
    @apply bg-blue-80;
    @apply text-white;
  }

  &-tr {
    @apply border border-blue-80;
    @apply text-blue-80;
  }

  &-red {
    @apply text-red-50;
    @apply border-red-50;
  }

  &-white {
    @apply border border-solid border-black-10;
    @apply bg-white;
    @apply border-white;
    @apply text-black;
  }

  &-black {
    @apply border-black;
    @apply text-black;
  }

  &-sm {
    font-size: 14px;
    line-height: 16px;
    height: 32px;
    @apply px-4;
  }

  &-md {
    @apply py-3 px-6;
    font-size: 14px;
    line-height: 16px;
    max-height: 40px;

    i {
      font-size: 10px;
      font-weight: bold;
    }
  }

  &-lg {
    @apply py-3 px-6;
  }

  &-xl {

  }

  &-new-goal {
    @apply text-blue-80;
    @apply border border-blue-80;
    @apply bg-blue-5;
    @apply font-semibold;
    @apply rounded-2xl;
    @apply flex items-center justify-center;
    @apply p-3.75;
    @apply mb-2;
    @apply w-full;
  }
}

[class^='status-'] {
  padding: 7px 15px;
  @apply rounded-2xl;
  @apply text-sm leading-4;
}

.status-paid,
.status-approved {
  @apply bg-green-5;
  @apply text-green-90;
  @apply border border-solid border-green-90;
  @apply capitalize;
}

.status-pending {
  @apply bg-black-5;
  @apply text-black-40;
  @apply border border-solid border-black-40;
  @apply capitalize;
}

.status-rejected {
  @apply bg-red-10;
  @apply text-red;
  @apply border border-solid border-red;
  @apply capitalize;
}

.btn-toggle-menu {
  @apply bg-blue-80;
  @apply text-white;
  @apply rounded-full;
  @apply absolute;
  top: 40px;
  right: -26px;
  width: 24px;
  height: 24px;
}

.very-bad {
  background-color: #A7064C;
}

.bad {
  background-color: #F67F3F;
}

.neutral {
  background-color: #FDBB05;
}

.good {
  background-color: #FFE700;
}

.very-good {
  background-color: #15A97D;
}
