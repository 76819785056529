@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon/icomoon.eot?ayorq1');
  src:  url('../fonts/icomoon/icomoon.eot?ayorq1#iefix') format('embedded-opentype'),
    url('../fonts/icomoon/icomoon.ttf?ayorq1') format('truetype'),
    url('../fonts/icomoon/icomoon.woff?ayorq1') format('woff'),
    url('../fonts/icomoon/icomoon.svg?ayorq1#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-arrow-left:before {
  content: "\e937";
}
.icon-arrow-righ:before {
  content: "\e938";
}
.icon-arrow-top:before {
  content: "\e939";
}
.icon-arrow-bottom:before {
  content: "\e93a";
}
.icon-file-check:before {
  content: "\e936";
}
.icon-image:before {
  content: "\e935";
}
.icon-file-download:before {
  content: "\e934";
}
.icon-chevrons-left:before {
  content: "\e932";
}
.icon-chevrons-right:before {
  content: "\e933";
}
.icon-share:before {
  content: "\e92f";
}
.icon-calendar-time:before {
  content: "\e930";
}
.icon-message-circle-2:before {
  content: "\e931";
}
.icon-map-pin:before {
  content: "\e92e";
}
.icon-upload:before {
  content: "\e92d";
}
.icon-circle-cancel:before {
  content: "\e92a";
}
.icon-circle-minus:before {
  content: "\e92b";
}
.icon-circle-plus:before {
  content: "\e92c";
}
.icon-download:before {
  content: "\e923";
}
.icon-filter-off:before {
  content: "\e924";
}
.icon-filter:before {
  content: "\e925";
}
.icon-goal-file .path1:before {
  content: "\e920";
  color: rgb(0, 54, 226);
  opacity: 0.3;
}
.icon-goal-file .path2:before {
  content: "\e921";
  margin-left: -0.7998046875em;
  color: rgb(0, 54, 226);
}
.icon-goal-file .path3:before {
  content: "\e922";
  margin-left: -0.7998046875em;
  color: rgb(0, 54, 226);
}
.icon-clock:before {
  content: "\e91e";
}
.icon-calendar-event:before {
  content: "\e91f";
}
.icon-x:before {
  content: "\e91d";
}
.icon-file-plus:before {
  content: "\e91b";
}
.icon-plus:before {
  content: "\e91c";
}
.icon-question-mark:before {
  content: "\e908";
}
.icon-settings:before {
  content: "\e90b";
}
.icon-user:before {
  content: "\e90c";
}
.icon-logout:before {
  content: "\e90d";
}
.icon-external-link:before {
  content: "\e907";
}
.icon-check:before {
  content: "\e906";
}
.icon-alert-triangle:before {
  content: "\e900";
}
.icon-chevron-right:before {
  content: "\e917";
}
.icon-arrows-sort-Asc .path1:before {
  content: "\e926";
  color: rgb(0, 54, 226);
}
.icon-arrows-sort-Asc .path2:before {
  content: "\e927";
  margin-left: -1.25em;
  color: rgb(208, 213, 221);
}
.icon-arrows-sort-Desc .path1:before {
  content: "\e928";
  color: rgb(208, 213, 221);
}
.icon-arrows-sort-Desc .path2:before {
  content: "\e929";
  margin-left: -1.25em;
  color: rgb(0, 54, 226);
}
.icon-arrows-sort:before {
  content: "\e90e";
  color: #d0d5dd;
}
.icon-trash:before {
  content: "\e915";
}
.icon-target:before {
  content: "\e918";
}
.icon-chevron-down:before {
  content: "\e919";
}
.icon-chevron-up:before {
  content: "\e91a";
}
.icon-calendar:before {
  content: "\e901";
}
.icon-chat:before {
  content: "\e902";
}
.icon-checkbox:before {
  content: "\e903";
}
.icon-document:before {
  content: "\e904";
}
.icon-documents:before {
  content: "\e905";
}
.icon-grid:before {
  content: "\e909";
}
.icon-info:before {
  content: "\e90a";
}
.icon-people:before {
  content: "\e90f";
}
.icon-person:before {
  content: "\e910";
}
.icon-school:before {
  content: "\e911";
}
.icon-edit:before {
  content: "\e912";
}
.icon-help:before {
  content: "\e913";
}
.icon-bell:before {
  content: "\e914";
}
.icon-chevron-left:before {
  content: "\e916";
}