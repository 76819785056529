.react-calendar {
  @apply p-6;
  @apply mb-4;
  @apply bg-white;
  @apply rounded-2xl;
  
  &__navigation {
    @apply flex justify-between;
    @apply mb-1;

    &__label {
      &__labelText {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        @apply text-black;
      }
    }
    
    &__arrow {
      color: transparent;
      font-size: 0;
      line-height: 40px;
      height: 40px;
      width: 30px;
      
      &:before {
        color: #000000;
        font-size: 14px;
        font-family: 'icomoon';
      }
    }
    
    &__next-button {
      &:before {
        content: '\e917';
      }
    }
    &__prev-button {
      &:before {
        content: '\e916';
      }
    }
    &__next2-button,
    &__prev2-button {
      @apply hidden;
    }
  }

  &__tile {
    height: 40px;
    transition: all .3s linear;
    @apply rounded-2xl;

    &--active {
      font-weight: 600;
      @apply text-white;
      @apply border-blue-80;
      @apply bg-blue-80;
    }
  }
  
  &__month-view {
    &__weekdays {
      @apply flex justify-between;

      &__weekday {
        font-weight: 600;
        height: 40px;
        line-height: 40px;
        width: 40px;
        @apply text-black text-center;

        abbr {
          text-decoration: none;
        }
      }
    }

    &__days {
      @apply gap-1;
      @apply flex justify-between;
      
      &__day {
        height: 40px;
        width: 40px;
        flex: none!important;
        font-weight: 400;
        transition: all .3s linear;
        @apply rounded-full;
        
        &--neighboringMonth {
          @apply text-black-20;
        }
      }
    }
  }
    
  &__century-view__decades,
  &__decade-view__years,
  &__year-view__months {
    @apply gap-y-4;
  }
}