@import './variables';

.rbc-event {
  border: none;
  box-sizing: border-box;
  box-shadow: none;
  margin: 0;
  color: #000;
  margin-bottom: 5px;
  background-color: #E5EAFC;
  border-left: 4px solid #335EE8!important;
  padding: 5px 6px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  text-align: left; 
  width: 90%;
  
  b {
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
    margin-bottom: 4px;
  }

  span {
    display: none;
    color: #666666;
    font-weight: 300;
    font-size: 10px;
    line-height: 12px;
  }
  
  &:nth-child(3n) {
    background-color: #000;
  }

  .rbc-slot-selecting & {
    cursor: inherit;
    pointer-events: none;
  }

  &.rbc-selected {
    background-color: #B3C3F6;
  }

  &:focus {
    outline: 5px auto $event-outline;
  }
}

.rbc-event-label {
  @extend .rbc-ellipsis;
}

.rbc-event-overlaps {
  box-shadow: -1px 1px 5px 0px rgba(51,51,51,.5);
}

.rbc-event-continues-prior {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.rbc-event-continues-after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}


.rbc-event-continues-earlier {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.rbc-event-continues-later {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
