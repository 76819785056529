@import './variables';

.rbc-row {
  display: flex;
  flex-direction: row;
}

.rbc-row-segment {
  padding: 0 1px 1px 1px;
  display: flex;
  justify-content: center;

  .rbc-event-content {
    display: flex;
    flex-direction: column;
    // @extend .rbc-ellipsis;
  }
}

.rbc-selected-cell {
  background-color: $date-selection-bg-color;
}

.rbc-show-more {
  @extend .rbc-ellipsis;
  background-color: rgba(255, 255, 255, 0.3);
  z-index: $event-zindex;
  height: auto;
  line-height: normal;
  color: $event-bg;
  &:hover,
  &:focus {
    color: darken($event-bg, 10%);
  }
}

.rbc-month-view {
  filter: drop-shadow(0px 4px 16px rgba(148, 163, 184, 0.15));
  @apply bg-white;
  @apply rounded-2xl;
  @apply border border-solid border-black-10;
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
  height: 100%; // ie-fix
  
  .rbc-event {
    span {
      display: block;
    }
  }
  
  .rbc-month-row {
    &:nth-child(3n-2) {
      .rbc-event {
        background-color: #FEEAEA;
        border-left: 4px solid #F84848!important;
      }
    }
    &:nth-child(3n-1) {
      .rbc-event {
        background-color: #E5EAFC;
        border-left: 4px solid #335EE8!important;    
      }
    }
    &:nth-child(3n) {
      .rbc-event {
        background-color: #E5F9E7;
        border-left: 4px solid #00BF11!important;
      }
    }
  }
}

.rbc-month-header {
  display: flex;
  flex-direction: row;
}

.rbc-month-row {
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1 0 0; // postcss will remove the 0px here hence the duplication below
  flex-basis: 0px;
  overflow: hidden;

  height: 100%; // ie-fix

  & + & {
    @apply border-t border-solid border-black-10;
  }
}

.rbc-date-cell {
  flex: 1 1 0;
  min-width: 0;
  padding: 8px 12px 0;
  text-align: right;

  &.rbc-now {
  }

  > a {
    &,
    &:active,
    &:visited {
      color: inherit;
      text-decoration: none;
    }
  }
}

.rbc-row-bg {
  @extend .rbc-abs-full;
  display: flex;
  flex-direction: row;
  flex: 1 0 0;
  overflow: hidden;
}

.rbc-day-bg {
  flex: 1 0 0%;

  & + & {
    @apply border-l border-solid border-black-10;
  }

  .rbc-rtl & + & {
    border-left-width: 0;
    @apply border-r border-solid border-black-10;
  }
}

.rbc-overlay {
  position: absolute;
  z-index: $event-zindex + 1;
  @apply border border-solid border-black-10;
  background-color: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
  padding: 10px;

  > * + * {
    margin-top: 1px;
  }
}

.rbc-overlay-header {
  @apply border-b border-solid border-black-10;

  margin: -10px -10px 5px -10px;
  padding: 2px 10px;
}
