@tailwind base;
@tailwind components;
@tailwind utilities;

// Fonts
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600;700;800&display=swap');

// Variables
@import 'variables';

// Fonts
@import 'fonts';

// Style main html elements
@import 'defaults';

// Icons converted from svg to fonts
@import 'icomoon';

// Custom icons
@import 'icons';

// Form elements
@import 'forms';

// Elements
@import 'elements';

// Big Calendar
@import 'calendar/styles';

// Components
@import 'components/side-calendar';