@import './variables';
@import './time-column';

.rbc-slot-selection {
  z-index: 10;
  position: absolute;
  background-color: $time-selection-bg-color;
  color: $time-selection-color;
  width: 100%;
  padding: 3px;
}

.rbc-slot-selecting {
  cursor: move;
}

.rbc-now {
  .rbc-button-link {
    height: 30px;
    margin-top: -3px;
    margin-right: -3px;
    width: 30px;
    @apply text-white;
    @apply bg-blue-80;
    @apply rounded-full;
  }
}

.rbc-time-view {
  @apply bg-white;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  border: 1px solid $calendar-border;
  min-height: 0;
  overflow: hidden;
  border-radius: 16px;
  max-height: 740px;

  .rbc-row {
    min-height: auto!important;
    // height: 0;
  }

  .rbc-header {
    border-bottom-width: 0;
    text-align: center;
    border-left: 0;
    
    &.rbc-today {      
      button {
        background-color: #335EE8;
        color: #fff;
        border-radius: 16px;
      }
    }

    button {
      padding: 2px 10px;
      font-size: 16px;
      color: #000;
      text-transform: uppercase;
    }
    
    .rbc-row {
      min-height: none;
      height: 0;
    }
  }

  .rbc-time-gutter {
    white-space: nowrap;
    text-align: right;
  }

  .rbc-allday-cell {
    box-sizing: content-box;
    width: 100%;
    height: 100%;
    position: relative;
  }
  .rbc-allday-cell + .rbc-allday-cell {
    border-left: 1px solid $cell-border;
  }

  .rbc-allday-events {
    position: relative;
    z-index: 4;
  }

  .rbc-row {
    box-sizing: border-box;
    min-height: 20px;
  }
}

.rbc-time-header {
  display: flex;
  flex: 0 0 auto; // should not shrink below height
  flex-direction: row;

  &.rbc-overflowing {
    // border-right: 1px solid $cell-border;
  }

  .rbc-rtl &.rbc-overflowing {
    border-right-width: 0;
    border-left: 1px solid $cell-border;
  }

  > .rbc-row:first-child {
    border-bottom: 1px solid $cell-border;
  }

  > .rbc-row.rbc-row-resource {
    border-bottom: 1px solid $cell-border;
  }

  // .rbc-gutter-cell {
  //   flex: none;
  // }

  // > .rbc-gutter-cell + * {
  //   width: 100%;
  // }
}

.rbc-time-header-cell-single-day {
  display: none;
}

.rbc-time-header-content {
  flex: 1;
  display: flex;
  min-width: 0;
  flex-direction: column;

  .rbc-rtl & {
    border-left-width: 0;
    border-right: 1px solid $cell-border;
  }

  > .rbc-row.rbc-row-resource {
    border-bottom: 1px solid $cell-border;
    flex-shrink: 0;
  }
}

.rbc-time-content {
  display: flex;
  flex: 1 0 0%;
  align-items: flex-start;
  width: 100%;
  border-top: 1px solid $calendar-border;
  overflow-y: auto;
  position: relative;

  > .rbc-time-gutter {
    flex: none;
  }

  > * + * > * {
    border-left: 1px solid $cell-border;
  }

  .rbc-rtl & > * + * > * {
    border-left-width: 0;
    border-right: 1px solid $cell-border;
  }

  > .rbc-day-slot {
    width: 100%;
    user-select: none;
    -webkit-user-select: none;
  }
}

.rbc-current-time-indicator {
  position: absolute;
  z-index: 3;
  left: 0;
  right: 0;
  height: 1px;

  background-color: $current-time-color;
  pointer-events: none;
}
