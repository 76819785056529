html,
body {
  @apply font-sans text-base;
  -webkit-print-color-adjust: exact;
}

html,
body,
#root {
  height: 100%;
}

h1 {
  font-weight: 800;
  color: black;
  @apply font-ancho;
  font-size: 45px;
  line-height: 52.75px;
}

h2 {
  @apply text-s2;
}

h3 {
  @apply text-s3;
}

h4 {
  @apply text-s4;
  @apply font-semibold;
}

h5 {
  @apply text-s5;
}

h6 {
  @apply text-s6;
}

h1,
h2,
h3,
h5,
h6 {
  @apply font-semibold;
}

a {
  @apply transition-colors;
  @apply ease-in-out;
  @apply duration-300;
}


i.icon-chevron-down {
  display: inline-block;
  transition: transform .2s ease-in-out;

  &.rotate {
    @apply rotate-180;
  }
}

button,
input,
optgroup,
select,
textarea {
  &:focus {
    outline: none;
  }
}

@media (max-width: 768px) {
 h1 {
   font-size: 35px
 }
}