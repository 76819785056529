@import './variables';

$active-background: darken($btn-bg, 10%);
$active-border: darken($btn-border, 12%);

.rbc-toolbar {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  
  button {
    transition: all .3s ease-in-out;
  }

  .rbc-toolbar-label {
    flex-grow:1;
    padding: 0 10px;
    text-align: left;
    @apply text-2xl;
  }
}

.next-prev-btn-group,
.rbc-btn-group:first-child {
  button {
    &:first-child {
      @apply text-blue-80;
      @apply mr-4;
    }
    
    &:not(:first-child) {
      font-size: 0;
      color: transparent;
      height: 32px;
      width: 48px;
      @apply border border-solid border-black;
      @apply rounded-2xl;

      &:before {
        font-family: "icomoon";
        @apply text-black text-xs;
        line-height: 30px;
      }
    }
    
    &:nth-child(2) {
      @apply mr-1;

      &:before {
        content: '\e916';
      }
    }

    &:nth-child(3) {
      &:before {
        content: '\e917';
      }
    }
  }
}

.time-btn-group,
.rbc-btn-group:not(:first-child) {
  border-radius: 20px;
  padding: 4px;
  display: inline-block;
  white-space: nowrap;
  @apply bg-white;
  
  & button {
    @apply text-blue-80;
    @apply rounded-2xl;
    display: inline-block;
    margin: 0;
    text-align: center;
    vertical-align: middle;
    background: none;
    background-image: none;
    // border: 1px solid $btn-border;
    padding: .375rem 1rem;
    line-height: normal;
    white-space: nowrap;
    
    &:hover {
      @apply text-white;
      @apply bg-blue-80;
    }

    &.rbc-active {
      @apply bg-blue-80;
      @apply text-white;
    }

    // &:focus {
    //   color: $btn-color;
    //   background-color: $active-background;
    //   border-color: $active-border;
    // }
    // 
    // &:hover {
    //   color: $btn-color;
    //   background-color: $active-background;
    //   border-color: $active-border;
    // }
  }
  
  button:not(:last-child) {
    margin-right: 2px;
  }
 
  .rbc-rtl & button + button {
    margin-left: 0;
    margin-right: -1px;
  }

  & + &,
  & + button {
    margin-left: 10px;
  }
}

.rbc-btn-group:not(:first-child) {
  display: none;
  visibility: hidden;
  opacity: 0;
  height: 0;
  width: 0;
}
