.icon-google {
  display: inline-block;
  background: url(../img/icons/icon-google.svg) center no-repeat;
  margin-right: 8px;
  height: 24px;
  width: 24px;
}

.icon-mood {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  height: 26px;
  width: 26px;
}

.icon-bad {
  background-image: url(../img/icons/icon-bad.svg);
}

.icon-sad {
  background-image: url(../img/icons/icon-sad.svg);
}

.icon-ok {
  background-image: url(../img/icons/icon-ok.svg);
}

.icon-good {
  background-image: url(../img/icons/icon-good.svg);
}

.icon-happy {
  background-image: url(../img/icons/icon-happy.svg);
}

.icon-google-drive {
  background-image: url(../img/icons/icon-google-drive.svg);
  height: 32px;
  width: 36px;
}

.icon-gmail {
  background-image: url(../img/icons/icon-gmail.svg);
  height: 32px;
  width: 43px;
}

.icon-goal-set {
  background-image: url(../img/icons/icon-goal-set.svg);
  height: 32px;
  width: 32px;
}

.icon-search {
  background-image: url(../img/icons/icon-search.svg);
  height: 24px;
  width: 24px;
}

.icon-mail {
  background-image: url(../img/icons/icon-mail.svg);
  height: 16px;
  width: 16px;
}

.icon-goal-file {
  background: #FFFFFF;
  border-radius: 8px;
  display: block;
  height: 40px;
  font-size: 20px;
  text-align: center;
  line-height: 40px;
  width: 40px;
}
