select,
textarea,
input[type="email"],
input[type="text"],
input[type="date"],
input[type="password"],
input[type="tel"],
input[type="url"],
input[type="number"], {
  padding: 7px 12px;
  @apply h-10;
  @apply border border-black-10 border-solid;
  @apply rounded-3.5;
  @apply w-full;

  &::placeholder {
    @apply text-black-20;
  }
}

input[type="date"] {
  &::-webkit-calendar-picker-indicator {}
}

textarea {
  min-height: 98px;
}

select {
  @apply text-black-20;
}

label {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #999999;
  @apply mb-1;
}

.form-row {
  @apply flex flex-col;
  @apply mb-8;


  > div {
    &:not(:last-child) {
      @apply mb-2;
    }
  }
}

.form-error{
  @apply border border-red #{!important};
}
